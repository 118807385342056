.todayPerfomanceContainer {
    box-sizing: border-box;
    border: 1px solid #EBECF2;
    width: 100%;
    padding: 32px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    /* max-height: 460px; */
}

.headingContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.headingSubContainer {
    display: flex;
    gap: 20px
}

.subInfoGreen {
    color: #5F854C;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.subInfoRed {
    color: #C94C40;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.infoBoxGreen {
    color: #5F854C;
    background: #EAF4DF;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.infoBoxRed {
    color: #C94C40;
    background: #FFF5F5;
    font-Size: 14px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}