.tableHeading {
    font-size: 17px;
    font-weight: 500;
    min-width: 141px;
    padding: 17px 0px 18px 0px;
    color: #12131A;
}

.container {
    width: 100%;
    height: inherit;
    overflow-y: scroll;
    height: 600px;
    border-bottom: 1px solid #D8DAE5;
    box-shadow: 0 -5px 3px -3px #D8DAE5;
    background-color: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
}

.tableContainer {
    width: inherit;

}

.headingContainerstart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
}

.headingContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
}

.row {
    color: #12131A;
    font-size: 18px;
}

.gainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.bottomValue {
    font-size: 16px;
    padding-top: 5px;
    /* color: #5F854C; */
    display: flex;
    align-items: center;
    padding-left: 2px;
}

.tbodyContainer {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 100px;
    height: 100px;
    background-color: #FFFFFF;
    cursor: default;
}







.refresh {
    display: flex;
    align-Items: center;
    gap: 20px;
    padding: 16px;
    box-shadow: 0 -3px 3px -3px #D8DAE5;
    background-color: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
}

.animationCont{
    margin: auto;
    width: 256px;
    height: 256px;
    position: relative;
}

.animateStaticIcon{
    position: absolute;
    top: 80px;
    left: -10px;
}

.blurTableHeading{
    background: #EAF4DF;
}