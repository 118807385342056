.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 48px 20px 36px;
  background-color: #FFFFFF;
}
.rightComponent {
  display: flex;
  flex-direction: row;
  gap: 12px;
  /* flex: 1 1 ; */
  justify-content: space-between;
  align-items: center;
}

.navBarIcons {
  margin-left: 12px;
}

.importBtn {
  margin-left: 12px;
  margin-right: 24px;
}

.userProfile{
  width:48px;
  height: 48px;
}
