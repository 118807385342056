.unrealisedGainWrapper{
    padding: 24px;
}

.unrealisedGainContainer{
    display: flex;
}

.unrealisedGainLeft{
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.unrealisedGainRight{
    width: 53%;
    border: 1px solid #C3DCBC;
    border-radius: 8px;  
    padding: 20px;
}

.unrealisedGainPercentageCont{
    align-items: center;
    display: flex;
}
   