.container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.heading {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
}

.header {
    padding: 24px;
}

.btnContainer {
    gap: 10px;
}

.infoContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 57px;
    text-align: center;
}

.tableContainer {
    flex: 1 1 100%;
    padding: 17px 24px 18px 24px;
    border-top: 1px solid #EBECF2;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #EBECF2;
}

.tableContainer .infoTableContainer {
    display: flex;
    width: 100%;
}

.assetName {
    color: #12131A;
    font-size: 20px;
    line-height: 25px;
    /* font-weight: 600px; */
}

.assetSymbol {
    color: #64677A;
    font-size: 16px;

}


.leftInfo {
    width: 40%;
}

.currentValue {
    font-size: 18px;
    padding-right: 12px;
    line-height: 25px;
}

.posPercentage {
    color: #5F854C;
    font-size: 18px;
    display: flex;
    gap: 5px;
    font-weight: normal;
    line-height: 27px;

}

.negPercentage {
    color: #C94C40;
    font-size: 18px;
    font-weight: normal;
    line-height: 27px;
    display: flex;
    gap: 5px;
}

.percentageGain {
    font-size: 16px;
    color: #5F854C;
    text-align: right;
    font-weight: 600;
}

.percentageLoss {
    font-size: 16px;
    color: #C94C40;
    text-align: right;
    font-weight: 600;
}

.btnContainerWatchList {
    margin: auto;
    margin-left: 10px
}

p {
    margin: 0px;
}

.watchListContainer::-webkit-scrollbar {
    /* display: none; */
    width: 6px;
}
.watchListContainer::-webkit-scrollbar-track{
    width: 6px;
}        

.watchListHeadingContainer {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 24px 24px;
    align-items: center;
    
}