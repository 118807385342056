.container {
    justify-content: center;
    align-content: center;
  }
  
  .XirrMainContainer {
    margin: 42px 30px 32px 40px;
    display: flex;
    justify-content: space-around;
  }
  
  .XirrPortfolio {
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    width: 48%;
    margin-left: 30px;
  }
  
  .TopXirrPortfolioContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
  
  .PortFolioXirr {
    width: 50%;
  }
  .valuationInvestmentContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .CurrXirrPortfolio {
    background-color: #cfe3c9;
    border-radius: 10px;
    position: absolute;
    left: -30px;
    top: -30px;
    width: 54%;
  }
  
  .PortfolioTxtContainer {
    display: "flex";
    justify-content: "center";
  }
  
  .valuationInvestment {
    margin: 24px;
  }
  
  .TotalGain {
    margin: 30px;
    margin-top: 400px;
  }
  
  .valuation {
    padding: 24px;
    border-radius: 8px;
    background-color: #f5f5f6;
  }
  
  
  .valuationPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .TotalInvestment {
    padding: 24px;
    justify-content: center;
    align-items: center;
  }
  
  .TotalInvestmentPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .TotalWithdrawal {
    padding: 24px;
    justify-content: center;
    align-items: center;
  }
  
  .TotalWithdrawalPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .BottomXirrPortfolioContainer {
    display: flex;
    justify-content: space-between;
    margin: 24px 40px;
  }
  
  .XirrOverAllPortfolio {
    /* border: 3px solid purple; */
    /* display: block; */
    /* margin: 42px 40px 32px 70px; */
  
    width: 48%;
   /* height: 715px; */
    /* margin-top: 60px; */
    /* justify-content: center;
    align-items: center; */
    background-color: #fff;
    border-radius: 8px;
  }
  
  .AssetsWisePerormance {
    margin: 56px 48px 64px 64px;
  }
  
  .AdvanceXirrChart {
    margin: 56px 48px 64px 64px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .AdvanceChartheading {
    display: flex;
  }
  
  .ComponentTabsStyle {
    margin-left: "12px";
  }
  
  .totalGainPercentageBox{
    background-color: #EAF4DF;
    border-radius: 6px;
    display: inline-flex;
    padding: 6px 8px;
  
  }
  .unrealisedGain{
    width: 28%;
  }
  .realisedGain{
    width: 28%;
  }
  .Income{
    width: 28%;
  }
  .AdvancePerformanceChartFooter{
    display: flex;
    align-items: center;
    padding: 28px 32px;
  }
  /* modal css */
  
  .modalContainer{
    background: white;
    border-radius: 10px;
    max-height: 90%;
  }


  .modalTop{
    display: flex;
  }
  .modalHeading{
    font-size: 24px;
    color: #12131A;
    font-weight: 600;
  }
  .closeIcon{
    color: #707070;
    font-size: 24px;
  }
  .modalSubHeading{
    font-size: 16px;
    font-size: 500;
    color: #12131A;
  }
  .doneButton{
    color: white;
    background-color: black;
  }
  .cancelButton{
    color: black;
    background-color: white;
  }
  .indexLabel{
    height: 56px;
    font-size: 18px;
  }
  .modalFooter{
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    border-top: 1px solid #EBECF2;
    gap: 12px
  }
  