.todayPerfomanceDoubleContainer {
    padding: 32px;
    margin: 20px;
    flex: 1 1 100%;
}

.subInfoGreenDouble {
    display: flex;
    align-items: center;
}

.subInfoRedDouble {
    color: #C94C40;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.infoBoxGreenDouble {
    color: #ffffff;
    background: #000000;
    font-size: 16px;
    font-weight: 400;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.infoBoxRedDouble {
    color: #C94C40;
    background: #FFF5F5;
    font-Size: 16px;
    font-weight: 400;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 15px;

}