.XirrBarChartContainer {
  padding: 24px;
  /* flex: 1 1 100%; */
}

.headingContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.headingSubContainer {
  display: flex;
  gap: 20px;
}

.dropdownContainer {
  display: flex;
  margin-left: auto;
}

.mainHeading {
  font-size: 20px;
  font-weight: 600;
}

.subHeading {
  color: #64677a;
  font-size: 16px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
}

.bulletsContainer {
  padding-top: 80px;
  padding-left: 40px;
  color: #64677a;
  width: 350px;
  display: flex;
  gap: 50px;
  float: right;
}

.subBulletContainer {
  color: #64677a;
}

.bulletsLayout {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bullets {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.pieChartContainer {
  width: 300px;
  height: 480px;
}

.subInfoGreen {
  color: red;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.subInfoRed {
  color: #c94c40;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.gainPctContainer {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  display: inline-block;
}

.gainPctBox {
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.infoBoxGreen {
  color: #5f854c;
  background: #eaf4df;
}

.infoBoxRed {
  color: #c94c40;
  background: #fff5f5;
}

.container {
  justify-content: center;
  align-content: center;
}

.XirrMainContainer {
  margin: 42px 30px 32px 40px;
  display: flex;
  justify-content: space-around;
}

.XirrPortfolio {
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  width: 48%;
  margin-left: 30px;
}

.TopXirrPortfolioContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.PortFolioXirr {
  width: 50%;
}

.valuationInvestmentContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.CurrXirrPortfolio {
  background-color: #cfe3c9;
  border-radius: 10px;
  position: absolute;
  left: -30px;
  top: -30px;
  width: 54%;
}

.PortfolioTxtContainer {
  display: "flex";
  justify-content: "center";
}

.valuationInvestment {
  margin: 24px;
}

.TotalGain {
  margin: 20px;
  margin-top: 350px;
}

.valuation {
  padding: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f5f5f6;
}

.valuationPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TotalInvestment {
  padding: 24px;
  justify-content: center;
  align-items: center;
}

.TotalInvestmentPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TotalWithdrawal {
  padding: 24px;
  justify-content: center;
  align-items: center;
}

.TotalWithdrawalPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.BottomXirrPortfolioContainer {
  display: flex;
  justify-content: space-between;
  margin: 24px 40px;
}

.XirrOverAllPortfolio {
  /* border: 3px solid purple; */
  /* display: block; */
  /* margin: 42px 40px 32px 70px; */

  width: 48%;
  /* height: 715px; */
  /* margin-top: 60px; */
  /* justify-content: center;
  align-items: center; */
  background-color: #fff;
  border-radius: 8px;
}

.AssetsWisePerormance {
  margin: 56px 48px 64px 64px;
}

.AdvanceXirrChart {
  margin: 56px 48px 64px 64px;
  background-color: #fff;
  border-radius: 8px;
}

.AdvanceChartheading {
  display: flex;
}

.ComponentTabsStyle {
  margin-left: "12px";
}

.totalGainPercentageBox {
  background-color: #EAF4DF;
  border-radius: 6px;
  display: inline-flex;
  padding: 6px 8px;

}

.unrealisedGain {
  width: 28%;
}

.realisedGain {
  width: 28%;
}

.Income {
  width: 28%;
}

.AdvancePerformanceChartFooter {
  display: flex;
  align-items: center;
  padding: 28px 32px;
}



.closeIcon {
  color: #707070;
  font-size: 24px;
}

.doneButton {
  color: white;
  background-color: black;
}

.cancelButton {
  color: black;
  background-color: white;
}