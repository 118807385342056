.XirrMainContainer {
  margin: 42px 40px 32px 40px;
}

/* .XirrPortfolio {
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  margin-left: 30px;
} */

.TopXirrPortfolioContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.PortFolioXirr {
  width: 45%;
}

.valuationInvestmentContainer {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.CurrXirrPortfolio {
  background-color: #cfe3c9;
  border-radius: 10px;
  position: absolute;
  left: -24px;
  top: -24px;
  max-width: 360px;
  width: 50%;
}

.PortfolioTxtContainer {
  display: "flex";
  justify-content: "center";
}

.valuationInvestment {
  margin: 22px 22px;
}

.TotalGain {
  margin: 30px;
  margin-top: 400px;
}


.valuationPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
}

.TotalInvestment {
  padding: 16px 23px;
  justify-content: center;
  align-items: center;
}

.TotalInvestmentPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TotalWithdrawal {
  padding: 16px 23px;
  justify-content: center;
  align-items: center;
}

.TotalWithdrawalPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.BottomXirrPortfolioContainer {
  display: flex;
  justify-content: space-between;
  margin: 21px 24px 10px 30px;
}

.XirrOverAllPortfolio {
  background-color: #fff;
  border-radius: 8px;
}

.AssetsWisePerormance {
  margin: 56px 48px 64px 64px;
}

.AdvanceXirrChart {
  margin: 56px 48px 64px 64px;
  background-color: #fff;
  border-radius: 8px;
}

.AdvanceChartheading {
  display: flex;
}

.ComponentTabsStyle {
  margin-left: "12px";
}

.totalGainLossPercentageBox {
  border-radius: 6px;
  display: inline-flex;
  padding: 6px 8px;
}

.totalGainPercentageBox {
  background-color: #EAF4DF;
}

.totalLossPercentageBox {
  background-color: #FFF5F5;
}

.unrealisedGain {
  width: 32%;
}

.realisedGain {
  width: 28%;
}

.income {
  width: 28%;
}

.incomeLong {
  width: 90%;
}

/* modal css */

.modalContainer {
  width: 34%;
  background: white;
  margin: auto auto;
  margin-top: 5%;
  border-radius: 10px;
}

.modalTop {
  display: flex;
}

.modalHeading {
  font-size: 24px;
  color: #12131A;
  font-weight: 600;
}

.closeIcon {
  color: #707070;
  font-size: 24px;
}

.modalSubHeading {
  font-size: 16px;
  font-size: 500;
  color: #12131A;
}

.doneButton {
  color: white;
  background-color: black;
}

.cancelButton {
  color: black;
  background-color: white;
}

.indexLabel {
  height: 56px;
  font-size: 18px;
  font-weight: 500;
}

.modalFooter {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  border-top: 1px solid #EBECF2;
}