body {
    position: relative;
  }
  
  .image {
    padding: 30px 0;
    height: 20px;
  }
  
  .selectBox {
    width: '100%';
    height: 91px;
    background: var(--primary-text) 0% 0% no-repeat padding-box;
    background: #12131a 0% 0% no-repeat padding-box;
    border-radius: 7px;
    color: "#000";
    border-bottom: 2px solid #3a3d4d;
    margin-bottom: 26px;
  }
  
  .name {
    color: white;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 20px !important;
  }
  
  .hideBorder {
    width: 272px;
    height: 91px;
    background: var(--primary-text) 0% 0% no-repeat padding-box;
    background: #12131a 0% 0% no-repeat padding-box;
    border-radius: 7px;
    color: "#000";
    border-bottom: none;
  }
  
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-family: Rubik, Regular;
    font-size: 16px;
    color: #ebecf2;
  }
  
  .selectFamily {
    width: 100%;
    color: white;
    background: transparent;
    padding: 20px 10px;
    z-index: 1;
    padding: 10px;
    border: none;
  }
  .portfolioSectionOfAssets {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 560px;
    scrollbar-width: thin;
    scrollbar-color: #babdcc #010006;
    margin: 16px 0;
    padding-right: 40px;
  }
  
  .portfolioOverview {
    width: 100%;
    height: 52px;
    padding: 0 16px;
    background: var(--unnamed-color-253326) 0% 0% no-repeat padding-box;
    background: #253326 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
  }
  
  .portfolioName {
    font: normal normal medium 16px/24px Rubik;
    letter-spacing: 0.11px;
    padding-left: 25px;
    font-size: 16px;
    color: #d0e3bb;
  }
  
  .assetClass {
    width: 85px;
    height: 19px;
    margin-top: 21px !important;
    color: #babdcc;
    text-align: left;
    letter-spacing: 0.11px;
    color: #babdcc;
  }
  
  .divider {
    background-color: #babdcc;
  }
  
  .dropdownDivider {
    background-color: #3a3d4d;
    width: 100%;
  }
  
  .addMoreAsset {
    background-color: #5F6F57;
    border: 1px solid #707070;
    border-radius: 12px;
    margin: 30px 26px;
    padding:10px
  }
  
  .text {
    padding: 16px;
    margin-bottom: 16px;
    font: normal normal medium 16px/24px Rubik;
    color: #fefefe;
  }
  
  .familyMembers {
    background: #1d2123;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 272px;
  }
  
  .boxImage {
    padding: 0px 12px;
    padding-top: 10px;
  }
  
  .family {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 16px 20px 16px;
    font-family: Rubik, Regular;
    color: #ebecf2;
  }
  
  .familyName {
    font-family: Rubik, Regular;
    font-size: 18px;
    color: #ebecf2;
    letter-spacing: 0.13px;
  }
  
  .portfolioSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 16px 0 16px;
    font-family: Rubik, Regular;
    font-size: 16px;
    color: #ebecf2;
  }
  
  .uparrow {
    transform: scaleY(-1);
  }
  
  .dropdownNav {
    width: 100%;
    max-width: 272;
    color: #babdcc;
  }
  
  .listItems {
    font-family: Rubik, Regular;
    font-size: 16px;
    color: #ebecf2;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    outline: none;
    border-radius: 8px;
    box-shadow: 24;
  }
  
  .familyNames {
    color: "#BABDC";
    padding: 0px 24px;
    background-color: #ffffff;
    border-radius: 8px 0px 0px 8px;
  }
  
  .searchBox {
    padding: 16px 28px 16px 0;
  }
  
  .familyTitle {
    color: #64677a !important;
    font-size: 18px !important;
  }
  
  .familiesList {
    overflow-y: scroll;
    height: 450px;
    color: #64677a;
    padding-right: 15px;
  }
  .familyNamesItems {
    color: "#64677A";
    border: 1px solid #ebecf2;
    margin: 0 0 16px 0;
    border-radius: 10px;
    width: 100%;
    font-family: Rubik, Regular;
    font-size: 20px;
    padding-bottom: 0;
  }
  
  .addNewFamilyBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eaf4df;
    border-radius: 10px;
    padding: 10px;
    margin: 0 10px 12px -14px;
    font-family: Rubik, Regular;
    font-weight: bold;
  }
  .addFamily {
    font-size: 18px;
    font-weight: 500;
  }
  
  /* Chrome, Edge, and Safari */
  .portfolioSectionOfAssets::-webkit-scrollbar {
    width: 4px;
  }
  
  .portfolioSectionOfAssets::-webkit-scrollbar-track {
    border-radius: 16px;
    background: #010006;
  }
  
  .portfolioSectionOfAssets::-webkit-scrollbar-thumb {
    background-color: #babdcc;
    border-radius: 16px;
    background-clip: padding-box;
  }
  .familiesList::-webkit-scrollbar {
    width: 4px;
    margin: 20px;
  }
  
  .familiesList::-webkit-scrollbar-track {
    border-radius: 16px;
    background: #ffffff;
  }
  
  .familiesList::-webkit-scrollbar-thumb {
    background-color: #babdcc;
    border-radius: 16px;
    background-clip: padding-box;
  }
  
  .flexHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px 0 24px;
    /* width: auto; */
  }
  .tooltip {
    position: absolute !important;
    top: 13%;
    background-color: #f9f9f9;
    z-index: 10000;
    width: 200px;
    border-radius: 10px;
    box-shadow: 1px 1px 15px #00000014;
  }
  
  .membersContainer {
    display: flex;
    justify-content: space-between;
  }

  .Divider {
    background-color: #d8dae5;
    width: 85% !important;
    margin: 18px 20px 17px 20px !important;
  }
  .portfolioHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 0 24px;
    color: #64677a;
    font-size: 16px;
  }
  
  
  .membersList {
    height: 460px;
    overflow: scroll;
    color: #64677a;
    scrollbar-width: 0;
    scrollbar-color: #f9f9f9 #f9f9f9;
    font-size: 12px;
    padding-left: 10px;
  }
  
  .membersList::-webkit-scrollbar {
    width: 4px;
    margin: 20px;
  }
  
  .membersList::-webkit-scrollbar-track {
    border-radius: 16px;
    background: #f9f9f9;
  }
  
  .membersList::-webkit-scrollbar-thumb {
    background-color: #f9f9f9;
    border-radius: 16px;
    background-clip: padding-box;
  }
  .membersItems {
    font-size: 18px;
    color: #64677a;
  }
  
  .icons {
    color: #010006;
    min-width: 24px;
  }
  
  .lastDivider {
    background-color: #d8dae5;
    width: 90% !important;
    margin: 18px 20px 17px 20px !important;
  }
  