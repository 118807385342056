.piechartWithLeftLegend_wrapper {
    padding: 0px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
  
  .headingContainer {
    display: flex;
    justify-content: space-between;
    flex: 0 0 54px;
  }
  
  .headingSubContainer {
    display: flex;
    gap: 20px;
  }
  
  .mainHeading {
    font-size: 20px;
    font-weight: 600;
  }
  
  .infoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }
  
  .bulletsContainer {
    padding-top: 20px;
    color: #64677a;
    max-width: 350px;
    display: flex;
    gap: 50px;
    max-height: 250px;
  }
  
  .pieChartContainer {
    min-width: 300px;
    height: 480px;
  }
  
  .tooltip_outer {
    max-width: 230px;
    padding: 5px 6px;
    background: #fff;
  }
  
  .tooltip_container {
    border-left: 3px solid grey;
    padding: 5px 10px;
    max-width: 230px;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  
  .tooltip_wrapper {
    display: flex;
    padding-bottom: 4px;
    justify-content: space-between;
    margin: 3px;
  }
  
  .tooltip_currentPrice {
    color: #505d6f;
    font-size: 16px;
    padding-right: 25px;
    font-weight: 600;
  }
  
  .tooltip_percentageCont {
    margin: 0px;
    padding: 3px;
    border-radius: 5px;
    background: #f5f5f6;
    color: #64677a;
    font-size: 14px;
  }
  
  .tooltip_loanText {
    font-size: 14px;
    color: #64677a;
    line-height: 12px;
  }