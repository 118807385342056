body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.recharts-layer.recharts-pie,
.recharts-sector:focus {
    outline: none !important;
    outline-color: none;
    outline-style: none;
    outline-width: none;
}


.performance-dropdown-assettype-icon:hover #performance-dropdown-assettype-icon-fillelement {
  fill: #5f854c
}


/* Recharts Axis */

.rechart-firstXitem-black .recharts-xAxis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick:first-of-type tspan {
  font-weight: 600;
  fill: #12131A;
}

.rechart-firstYitem-black .recharts-yAxis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick:first-of-type tspan {
  font-weight: 600;
  fill: #12131A;
}

/* Recharts Today Performance Chart */

.today-perf-rechart .recharts-surface {
  overflow: visible;
}