.amountInvestedWrapper{
    height: 100%;
    box-sizing: border-box;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.yourInvestment{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconCont{
    min-width: 35px;
    display: flex;
    justify-content: flex-end;
    width: 48px;
}