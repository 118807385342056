.modalContainer {
    background: white;
    border-radius: 10px;
    max-height: 90%;
}


.itemLabel {
    height: 56px;
    font-size: 18px;
    font-weight: 500;
}

.modalFooter {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-top: 1px solid #EBECF2;
}