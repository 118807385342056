.subInfoRedDouble {
    color: #C94C40;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.topLabel {
    color: #ffffff;
    background: #000000;
    font-family: Rubik, Regular;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoBoxRedDouble {
    color: #C94C40;
    background: #FFF5F5;
    font-Size: 16px;
    font-weight: 400;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 15px;

}