.container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.heading {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
}

.header {
    padding: 24px 24px 0 24px;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 15px ;
}

.infoContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 57px;
    text-align: center;
}

.infoContainerSubHeading {
    color: #64677A;
    letter-spacing: 0.1px;
    padding-bottom: 19px;
}

.tableContainer {
    margin: 12px;
    padding: 17px 16px 18px 16px;
    border: 1px solid #EBECF2;
    border-radius: 4px;
    opacity: 1;
}

.tableContainer .infoTableContainer {
    display: flex;

}


.posPercentage {
    color: #5F854C;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    justify-content: flex-end;
}

.negPercentage {
    color: #C94C40;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

.percentageGain {
    font-size: 16px;
    color: #5F854C;
    text-align: right;
    font-weight: 600;
}

.percentageLoss {
    font-size: 16px;
    color: #C94C40;
    text-align: right;
    font-weight: 600;
}

p {
    margin: 0px;
}

.leaderBoradContainer {
    overflow-y: auto;
    overflow-x: hidden; 
    flex-grow: 1;
    min-height: 0; 
    margin-bottom: 15px;
    padding: 12px;
}

.leaderBoradContainer::-webkit-scrollbar {
    width: 1px;
}

/* .leaderBoradContainer::-webkit-scrollbar-thumb {
    background: rgb(186, 189, 204, 0.4);
    border-radius: 16px;
} */