.todayPerfomanceContainer {
    padding: 32px 0px 32px 0px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    width: max-content;
    flex: 0 0 60px;
    padding-left: 32px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.headingSubContainer {
    display: flex;
    gap: 20px;
}

.mainHeading {
    font-size: 20px;
    font-weight: 600;
}

.subHeading {
    color: #64677a;
    font-size: 16px;
}




.bulletsLayout {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
}

.bullets {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.tooltip_outer {
    max-width: 230px;
    padding: 5px 6px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 4px;
}

.tooltip_container {
    padding: 5px 10px;
    max-width: 230px;
    /* background: #fff; */
    background: #ffffff 0% 0% no-repeat padding-box;
}

.tooltip_wrapper {
    display: flex;
    padding-bottom: 4px;
    /* align-items: flex-start; */
    justify-content: space-between;
    margin: 3px;
}

.tooltip_currentPrice {
    color: #505d6f;
    font-size: 16px;
    padding-right: 25px;
    font-weight: 600;
}

.tooltip_percentageCont {
    margin: 0px;
    padding: 3px;
    border-radius: 5px;
    background: #f5f5f6;
    color: #64677a;
    font-size: 14px;
}

.tooltip_loanText {
    font-size: 14px;
    color: #64677a;
    line-height: 12px;
}

.dropDown {
    display: none;
}

.recharts-legend-wrapper {
    width: 100%;
    left: 0;
}